import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const Index = () => {

    return (
        <Layout>
            <SEO
                title="Photo Gallery"
                description="Our photo gallery contains some of the best work done by Glendale Builders. From bathrooms to kitchens and floors, take a look and see what we can do for you."
            />
            <section className="main-gallery" aria-label="Photo Gallery">
                <div className="main-gallery__container">
                    <h2 className="main-gallery__title">
                        Photo Gallery
                    </h2>
                    <div className="main-gallery__wrapper">
                        <section className="main-gallery__type" aria-label="See Exterior Photos">
                            <div className="main-gallery__card">
                                <Link to="/gallery/exteriors" className="main-gallery__link">
                                    <div className="main-gallery__content">
                                        <h3 className="main-gallery__category">
                                            Exteriors
                                        </h3>
                                    </div>
                                    <div className="main-gallery__asset">
                                        <StaticImage src="../../images/exteriors/910 Greendale.jpg" className="main-gallery__image" alt="" />
                                        <p className="main-gallery__text">View all Exteriors</p>
                                    </div>
                                    <button className="main-gallery__button">
                                        View Exteriors
                                    </button>
                                </Link>
                            </div>
                        </section>
                        <section className="main-gallery__type" aria-label="See Interior Photos">
                            <div className="main-gallery__card">
                                <Link to="/gallery/interiors" className="main-gallery__link">
                                    <div className="main-gallery__content">
                                        <h3 className="main-gallery__category">
                                            Interiors
                                        </h3>
                                    </div>
                                    <div className="main-gallery__asset">
                                        <StaticImage src="../../images/interiors/14_924BurtonTer_23_WineCellar_HiRes.jpg" className="main-gallery__image" alt="" />
                                        <p className="main-gallery__text">View all Interiors</p>
                                    </div>
                                    <button className="main-gallery__button">
                                        View Interiors
                                    </button>
                                </Link>
                            </div>
                        </section>
                        <section className="main-gallery__type" aria-label="See Kitchen Photos">
                            <div className="main-gallery__card">
                                <Link to="/gallery/kitchens" className="main-gallery__link">
                                    <div className="main-gallery__content">
                                        <h3 className="main-gallery__category">
                                            Kitchens
                                        </h3>
                                    </div>
                                    <div className="main-gallery__asset">
                                        <StaticImage src="../../images/kitchens/kitchen2_3406meadow.jpg" className="main-gallery__image" alt="" />
                                        <p className="main-gallery__text">View all Kitchens</p>
                                    </div>
                                    <button className="main-gallery__button">
                                        View Kitchens
                                    </button>
                                </Link>
                            </div>
                        </section>
                        <section className="main-gallery__type" aria-label="See Bathroom Photos">
                            <div className="main-gallery__card">
                                <Link to="/gallery/bathrooms" className="main-gallery__link">
                                    <div className="main-gallery__content">
                                        <h3 className="main-gallery__category">
                                            Bathrooms
                                        </h3>
                                    </div>
                                    <div className="main-gallery__asset">
                                        <StaticImage src="../../images/bathrooms/Master Bathroom.jpg" className="main-gallery__image" alt="" />
                                        <p className="main-gallery__text">View all Bathrooms</p>
                                    </div>
                                    <button className="main-gallery__button">
                                        View Bathrooms
                                    </button>
                                </Link>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Index;
